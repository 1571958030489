import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import { useScrollToQuestion } from '@/hooks/useScrollToQuestion.js';

import { useStyles as useAnamnesisStyles } from '@/pages/HealthProfileAnamnesis/YourBodyComponents/YourBodyStyles.js';

import { useCustomAnamnesisStore } from '@/store.js';

import { useCustomAnamnesisStyles } from '../customAnamnesisStyles.js';

export const useStyles = makeStyles(() => ({
    title: {
        fontFamily: 'MessinaSans-Regular',
        fontSize: 18,
        fontWeight: 'normal',
    },
    textfield: {
        height: '3.75rem',
    },
    error: {
        boxShadow: '0 0 0 1px #da3030',
        borderRadius: 5,
    },
}));

const DatefieldQuestion = ({ question, answer, setAnswer, isSubQuestion = false, hideTitle = false }) => {
    const anamnesisClasses = useAnamnesisStyles();
    const classes = useStyles();
    const customAnamnesisStyles = useCustomAnamnesisStyles();
    const highlightedQuestions = useCustomAnamnesisStore((state) => state.highlightedQuestions);
    const questionRef = useScrollToQuestion(question);

    const onChange = (e) => {
        if (!isSubQuestion) {
            setAnswer(e.target.value);
        } else {
            setAnswer({
                ...answer,
                subQuestions: {
                    ...answer.subQuestions,
                    [question.question_key[0].text]: e.target.value,
                },
            });
        }

        if (e.target.value && highlightedQuestions[question.question_key[0].text]) {
            useCustomAnamnesisStore.setState({
                highlightedQuestions: {
                    ...highlightedQuestions,
                    [question.question_key[0].text]: false,
                },
            });
        }
    };

    return (
        <div
            ref={questionRef}
            className={clsx(
                customAnamnesisStyles.defaultQuestion,
                highlightedQuestions[question.question_key[0].text] && customAnamnesisStyles.highlight
            )}
        >
            {!isSubQuestion && !hideTitle && (
                <Typography style={{ marginBottom: '1rem' }} className={classes.title}>
                    {question.title[0].text}
                </Typography>
            )}
            <form noValidate>
                <TextField
                    label={!isSubQuestion ? question.label[0]?.text : question.title[0].text}
                    type="date"
                    variant="filled"
                    autoComplete="off"
                    color="primary"
                    value={(!isSubQuestion ? answer : answer?.subQuestions[question.question_key[0].text]) ?? ''}
                    onChange={onChange}
                    classes={{
                        root: clsx(classes.textfield),
                    }}
                    style={{ width: '100%' }}
                    InputLabelProps={{
                        classes: {
                            root: anamnesisClasses.inputLabel,
                            focused: 'focused',
                        },
                        shrink: true,
                    }}
                    InputProps={{
                        classes: {
                            root: anamnesisClasses.filledInputRoot,
                            focused: anamnesisClasses.fieldFocused,
                        },
                    }}
                />
            </form>
        </div>
    );
};

export default DatefieldQuestion;
