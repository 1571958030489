import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { orderBy } from 'lodash';
import { useContext, useEffect, useState } from 'react';

import Loader from '@/components/Loader';

import { ContextProvider } from '@/context/ProjectContext';

import { useConfigStore } from '@/store.js';

import PastAppointmentAccordion from './PastAppointmentAccordion';

const useStyles = makeStyles((theme) => ({
    subHeading: {
        ...theme.typography.HL2,
        marginBottom: '1rem',
    },
    appointmentContainer: {
        marginBottom: '2rem',
        maxWidth: theme.layout.contentWidth,
    },
}));

const PastAppointments = ({
    dataSet,
    mainData,
    dataSetDoctos,
    inactiveResponseData, // past appointments
    addAppointmentToUpcoming,
    showAllAppointments,
    isLoading = false,
}) => {
    const classes = useStyles();
    const { calenderCancelledData } = useContext(ContextProvider);
    const [appointments, setAppointmentsData] = useState(null);
    const currentCustomer = useConfigStore((state) => state.currentCustomer);

    useEffect(() => {
        if (!inactiveResponseData) return;

        const customer = useConfigStore.getState().currentCustomer;
        const customer_id = customer?.customer_id?.[0].text;

        const sortedArray = orderBy(inactiveResponseData, [(d) => new Date(d.start)], ['desc']);
        setAppointmentsData(
            showAllAppointments ? sortedArray : sortedArray.filter((entry) => entry.customer_id === customer_id)
        );
    }, [inactiveResponseData, showAllAppointments]);

    useEffect(() => {
        if (calenderCancelledData && inactiveResponseData) {
            let isPresent = false;
            inactiveResponseData?.forEach((a) => {
                if (a.id === calenderCancelledData.id) {
                    isPresent = true;
                }
            });
            if (!isPresent) {
                addAppointmentToUpcoming(calenderCancelledData);
            }
        }
    }, [calenderCancelledData, inactiveResponseData]);

    if (isLoading) return <Loader height="100px" />;

    return (
        <div>
            <Typography className={classes.subHeading}>
                {dataSet && mainData?.inactive_title?.[0]?.text ? mainData.inactive_title[0].text : 'Past Appointments'}
            </Typography>
            {appointments &&
                appointments.map((appointment) => (
                    <div key={appointment.id} className={classes.appointmentContainer}>
                        <PastAppointmentAccordion
                            dataSetDoctos={dataSetDoctos}
                            appointment={appointment}
                            dataSet={dataSet}
                            mainData={mainData}
                        />
                    </div>
                ))}
        </div>
    );
};

export default PastAppointments;
