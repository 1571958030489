import { isEqual } from 'lodash';
import { useCallback, useContext, useState } from 'react';

import { Context as AuthContext } from '@/context/AuthContext';
import { ConsentFormsContext } from '@/context/ConsentFormsContext';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';
import { ContextProvider } from '@/context/ProjectContext';

import { insuranceApi } from '@/service/AnamnesisSectionAPI/AccountHealthAPI';
import { adressApi, userAccountsApi } from '@/service/AnamnesisSectionAPI/PersonalnformationAPI';
import { consentFormServiceUpdate, formsRequestHandler } from '@/service/ConsentForms/consentFormService';

import { dayjs } from '@/utils/dayjsSetup.js';

export const useConsentFormsApi = () => {
    // Local State Variables
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);

    /** Context */
    const {
        state: { user, usersession },
        setuser,
    } = useContext(AuthContext);
    const { formData } = useContext(ConsentFormsContext);
    const { languageGlobal } = useContext(ContextProvider);
    const { insuranceCompany, insuranceId, insuranceType } = useContext(ContextProviderTwo);

    /**
     *  @param { body, isLastPage, isCommunication = false, isAllFormsLastPage = false, isInsurance }
     *  @returns { Promise } res
     */
    const sendConsentForm = useCallback(
        async ({
            body,
            isLastPage,
            isCommunication = false,
            isAllFormsLastPage = false,
            isInsurance = false,
            isHerakles = false,
            isUpdateForm = false,
        }) => {
            const isLoggedIn = !!user;

            if (isAllFormsLastPage) {
                setLoading(true);
                try {
                    const res = await formsRequestHandler({
                        body: body,
                        isHerakles: isHerakles,
                        isLoggedIn: isLoggedIn,
                    });
                    setLoading(false);
                    setResponse(res);

                    return res;
                } catch (error) {
                    setLoading(false);
                    setError(error);
                }
            } else if (user) {
                const isInsurancePrivate = insuranceType === 'PRIVATE';
                const isSelfPayer = insuranceType === 'SELFPAYER';

                // For registered users
                const ADDRESS_API_BODY = {
                    user_id: user?.id,
                    street: formData?.street,
                    zip: formData?.zipCode,
                    city: formData?.city,
                    country: {
                        code: formData?.country?.code,
                        name: formData?.country?.name,
                    },
                };

                const USER_ACCOUNT_API_BODY = {
                    name: {
                        first: formData?.firstName,
                        middle: formData?.middleName,
                        last: formData?.lastName,
                    },
                    ...('phoneNo' in formData && { phone: formData?.phoneNo }),
                    date_of_birth: dayjs(formData?.dob, 'DD.MM.YYYY', true).isValid()
                        ? dayjs(formData?.dob, 'DD.MM.YYYY').format('YYYY-MM-DD')
                        : formData?.dob,
                    title: formData?.title === '-' ? '' : formData?.title,
                    gender: formData?.gender || '',
                };

                const HEALTH_INSURANCE_API_BODY = {
                    user_id: user?.id,
                    insurance_type:
                        insuranceType?.toUpperCase() === 'SELFPAYER' ? 'SELF-PAYER' : insuranceType?.toUpperCase(),
                    ...(!isSelfPayer && {
                        insurance_id: insuranceId || '',
                    }),
                    ...(isInsurancePrivate && {
                        insurance_company: insuranceCompany,
                    }),
                };

                try {
                    setLoading(true);
                    const res = await Promise.all([
                        ...(isLastPage
                            ? [formsRequestHandler({ body: body, isHerakles: isHerakles, isLoggedIn: isLoggedIn })]
                            : []),
                        ...(isCommunication
                            ? [formsRequestHandler({ body: body, isHerakles: isHerakles, isLoggedIn: isLoggedIn })]
                            : []),
                        ...(isInsurance ? [insuranceApi(usersession, HEALTH_INSURANCE_API_BODY)] : []),
                        ...(isUpdateForm ? [consentFormServiceUpdate(body)] : []),
                        ...(!isLastPage
                            ? [
                                  adressApi(usersession, ADDRESS_API_BODY),
                                  userAccountsApi(usersession, USER_ACCOUNT_API_BODY)
                                      .then((response) => {
                                          if (!isEqual(user, response)) setuser(response);
                                      })
                                      .catch((error) => {
                                          console.debug('Error', error);
                                      }),
                              ]
                            : []),
                    ]);

                    const response = await Promise.all(
                        res.forEach((r, i) => {
                            if (i === res.length - 1) {
                                setLoading(false);
                                setResponse(res);
                            }
                        })
                    );

                    return response;
                } catch (error) {
                    setLoading(false);
                    setError(error);
                }
            } else {
                // For un-registered users
                setLoading(true);
                try {
                    const res = await formsRequestHandler({
                        body: body,
                        isHerakles: isHerakles,
                        isLoggedIn: isLoggedIn,
                    });
                    setLoading(false);
                    setResponse(res);

                    return res;
                } catch (error) {
                    setLoading(false);
                    setError(error);
                }
            }
        },
        [user, insuranceType, formData, languageGlobal, insuranceId, insuranceCompany, usersession]
    );

    return { sendConsentForm: sendConsentForm, loading: loading, error: error, response: response };
};
