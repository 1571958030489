//* * React */
//* * MUI */
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
//* * Others */
import clsx from 'clsx';
import { clone, uniqBy } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
//* * Router */
import { useHistory } from 'react-router-dom';
import isEmail from 'validator/es/lib/isEmail';

//* * Styled Component */
import BorderLinearProgress from '@/components/Common/BorderLinearProgress';
import CardContainer from '@/components/Common/CardContainer';
//* * Reusable Components */
import StepMenu from '@/components/Common/StepMenu/StepMenu';
//* * Styles & Child Components */
import { ConsentStyles, VerifyAccount } from '@/components/ConsentForms';
import FilledButton from '@/components/FilledButton';
import OutlinedButton from '@/components/OutlinedButton';

import { Context as AuthContext } from '@/context/AuthContext';
//* * Context */
import {
    ALL_FORM_BASE_URLS,
    ConsentFormsContext,
    FormTypes,
    UserType,
    toggleValues,
} from '@/context/ConsentFormsContext';
import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

//* * API HOOKS */
import { useConsentFormsApi } from '@/hooks/useConsentForms';
import useConsentFormsData from '@/hooks/useConsentFormsData';
import useHealthInsurance from '@/hooks/useHealthInsurance';
//* * Hooks */
import usePrismicData from '@/hooks/usePrismicData';
import { useSignUp } from '@/hooks/useSignUp';
import useUserAddress from '@/hooks/useUserAddress';
import useUserData from '@/hooks/useUserData';
import { useVerifyCode } from '@/hooks/useVerifyCode.js';

import { dayjs } from '@/utils/dayjsSetup.js';
//* * Helpers */
import {
    emailExists,
    getISODate,
    getKeyByValue,
    getText,
    isAllFormsContain,
    languageMap,
    useQuery,
    validateDOB,
} from '@/utils/helpers.js';

import { usePersistedPrismicStore } from '@/store.js';

import CommunicationAndContract from './Pages/CommunicationAndContract';
import CreateAccount from './Pages/CreateAccount';
//* * Pages */
import PersonalInformation from './Pages/PersonalInformationPage';
import SummaryAndConsent from './Pages/SummaryAndConsentPage';

const HeraklesCommunicationForms = () => {
    const classes = ConsentStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();

    const lan = languageMap[i18n.language];

    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

    const history = useHistory();
    const query = useQuery();
    const step = query.get('step');

    /** Context */
    const {
        consentFormsContent,
        whichEnum,
        setConfirmSummary,
        confirmSummary,
        communicationData,
        isAllForms,
        setAllFormsPages,
        allFormsPages,
        movePage,
        //* * Password */
        passwordError,
        confirmPasswordError,
        password,
        confirmPassword,
        validatePassword,
        //* * Eterno Account States */
        createAccount,
        verificationCode,
        policyAccepted,
        formData,
        setErrors,
        errors,
        userType,
        setUserType,
        summaryEdit,
        setSummaryEdit,
        signUpUser: signUpUserContext,
        communicationToggles,
        isFromOwnWelcome,
        allFormsStepCounter,
        courseStartDate,
        pagesStepsNo,
    } = useContext(ConsentFormsContext);

    const { isNotLastForm, formCount } = allFormsStepCounter;

    const { validUntil, insuranceCompany, insuranceId, insuranceNumber, insuranceStatus, insuranceType } =
        useContext(ContextProviderTwo);

    const {
        state: { user },
    } = useContext(AuthContext);

    const consentFormsTreatment = usePersistedPrismicStore((state) => state.consentFormsTreatment);
    /** -- Herakles All Forms Prismic */
    const prismicDataHerakles = usePersistedPrismicStore((state) => state.heraklesCommunication);

    const { loading: treatmentLoading, content } = usePrismicData(consentFormsTreatment, 'welcome_treatment_contract');
    const { loading: popupLoading, content: popupContent } = usePrismicData(consentFormsTreatment, 'signature_popup');
    const { loading: translationLoading, content: translationContent } = usePrismicData(
        consentFormsTreatment,
        'translation_popup'
    );
    const { loading: extendedLoading, content: extendedContent } = usePrismicData(
        consentFormsTreatment,
        'extended_treatment_contract'
    );
    const { loading: messagePageLoading, content: messagePageContent } = usePrismicData(
        consentFormsTreatment,
        'consent_forms_-_welcome_page'
    );
    const { loading: heraklesLoading, content: heraklesContent } = usePrismicData(prismicDataHerakles);

    useConsentFormsData(
        {
            treatmentLoading: treatmentLoading,
            popupLoading: popupLoading,
            translationLoading: translationLoading,
            extendedLoading: extendedLoading,
            messagePageLoading: messagePageLoading,
            heraklesLoading: heraklesLoading,
        },
        {
            content: content,
            popupContent: popupContent,
            translationContent: translationContent,
            extendedContent: extendedContent,
            messagePageContent: messagePageContent,
            heraklesContent: heraklesContent,
        }
    );

    useUserData();
    useHealthInsurance();
    useUserAddress();

    //* * Use SignUp Hook */
    const { signUp, user: signUpUser, loading: isLoading } = useSignUp();
    const {
        verifyCode,
        response: verificationResponse,
        loading: isVerifying,
        error: verificationError,
    } = useVerifyCode();

    //* * Use API Hook */
    const { loading: apiLoading, sendConsentForm } = useConsentFormsApi();

    //* * Current Flow Pages */
    const stepsEnum = {
        'personal-information': 'personal-information',
        'communication-and-contract': 'communication-and-contract',
        ...(!user && isAllForms && !isNotLastForm && { 'create-account': 'create-account' }),
        ...(!user &&
            isAllForms &&
            !isNotLastForm &&
            createAccount === toggleValues.YES && { verification: 'verification' }),
        'summary-and-consent': 'summary-and-consent',
    };

    /** Child Pages */
    const pages = useMemo(
        () => ({
            'personal-information': PersonalInformation,
            'communication-and-contract': CommunicationAndContract,
            ...(!user && isAllForms && !isNotLastForm && { 'create-account': CreateAccount }),
            ...(!user &&
                isAllForms &&
                !isNotLastForm &&
                createAccount === toggleValues.YES && { verification: VerifyAccount }),
            'summary-and-consent': SummaryAndConsent,
        }),
        [createAccount, isAllForms, isNotLastForm, user]
    );

    const currentStepNo = Object.keys(pages).findIndex((p) => p === step);
    const currentStepName = Object.keys(pages).find((p) => p === step);
    const isLastPage = Object.keys(pages)[Object.keys(pages).length - 1] === currentStepName;

    const navigateToStepOne = () => {
        if (step === 'verification' && isAllForms && currentStepNo < 0) {
            movePage(whichEnum.NEXT, history, null, !!user);
        } else if (currentStepNo < 0) {
            history.replace(`/consent-communication?step=${Object.keys(pages)[0]}`);
        }
    };

    useEffect(navigateToStepOne, []);

    //* * Disabled continue button on account page */
    const isAccountDisabled =
        (createAccount === toggleValues.YES && !policyAccepted) ||
        (currentStepName === 'create-account' && !createAccount);

    const { otherToggle } = communicationToggles;
    const { email, sms, other } = communicationData;

    //* * Disable if other is checked but the value is not provided */
    const isCommunicationCheck = otherToggle && !other;
    //* * If no toggle is checked return true */
    const isCheckDisabled = !Object.keys(communicationToggles).some((key) => communicationToggles[key]);
    //* * If either single value is given return true */
    const isCommunicationEnabled = !!email || !!sms || !!other;

    //* * States */
    const [pageTitles, setPageTitles] = useState({
        'personal-information': 'Personal Information',
        'communication-and-contract': 'communication-and-contract',
        ...(!user && isAllForms && !isNotLastForm && { 'create-account': 'create-account' }),
        ...(!user &&
            isAllForms &&
            !isNotLastForm &&
            createAccount === toggleValues.YES && { verification: 'create-account' }),
        'summary-and-consent': 'Summary And Consent',
    });

    // State to stop continous API calls
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (!heraklesLoading) {
            //* * Setting page titles for progress bar */
            setPageTitles({
                'personal-information': getText(heraklesContent?.personal_information_nav),
                'communication-and-contract': `${getText(heraklesContent?.title_nav)} & ${getText(
                    heraklesContent?.treatment_details_nav
                )}`,
                ...(!user &&
                    isAllForms &&
                    !isNotLastForm && {
                        'create-account': getText(extendedContent?.eterno_account) || 'create-account',
                    }),
                ...(!user &&
                    isAllForms &&
                    !isNotLastForm &&
                    createAccount === toggleValues.YES && {
                        verification: getText(extendedContent?.eterno_account) || 'create-account',
                    }),
                'summary-and-consent': getText(heraklesContent?.summary_and_consent_nav),
            });
        }
    }, [
        createAccount,
        extendedContent?.eterno_account,
        heraklesContent,
        heraklesLoading,
        isAllForms,
        isNotLastForm,
        user,
    ]);

    useEffect(() => {
        if (!userType) {
            if (user) {
                setUserType(UserType.REGISTERED);
            } else {
                setUserType(UserType.UN_REGISTERED);
            }
        }

        //* * Callback to redirect back user to first page */
        const redirectBack = () => history.replace('/all-forms-checkin');

        if (!isAllForms && !isFromOwnWelcome) {
            redirectBack();
        }

        if (confirmSummary) setConfirmSummary(!confirmSummary);

        window.addEventListener('load', redirectBack);

        const onFormReload = (e) => {
            if (window.location.pathname === '/consent-communication' && isAllForms) {
                // Cancel the event
                e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
                // Chrome requires returnValue to be set
                e.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', onFormReload);
    }, []);

    /** Current Page */
    const renderPage = useCallback(() => {
        const CurrentPage = pages[step] || 'p';
        return <CurrentPage />;
    }, [pages, step]);

    /** Returns Next Or Previous Step Name */
    const getStepName = useCallback(
        (which = whichEnum.NEXT) => {
            const keys = Object.keys(pages);
            const index = keys.indexOf(currentStepName);
            const whichIndex = which === whichEnum.NEXT ? index + 1 : index - 1;
            const item = keys[whichIndex];
            return item;
        },
        [currentStepName, pages, whichEnum.NEXT]
    );

    const getNextButtonText = useCallback(() => {
        const text = consentFormsContent?.buttons?.continue || 'Continue';
        const sendText = consentFormsContent?.heraklesContent?.sendButton || 'Send';
        const saveText = consentFormsContent?.heraklesContent?.save || 'Save';

        if (isLastPage) return sendText;
        if (currentStepName === 'personal-information' && isAllForms && summaryEdit) return saveText;

        //* * If not confirmed */
        return text;
    }, [consentFormsContent, currentStepName, isAllForms, isLastPage, summaryEdit]);

    /** Returns 'true' if current form is last form from AllForms else 'false' */
    const isAllFormsLastPage = useMemo(() => {
        if (allFormsPages.length) {
            const lastFormObject = allFormsPages[allFormsPages.length - 2];
            const lastFormName = lastFormObject?.route?.split('step')[0];
            const isLastForm = lastFormName?.includes(window.location.pathname);
            return isLastForm;
        }
        return false;
    }, [allFormsPages]);

    /** Returns allForms form types for API body */
    const getAllFormTypes = useMemo(() => {
        if (allFormsPages.length) {
            const newAllFormsPages = allFormsPages.slice(1, -1);
            const uniqueForms = uniqBy(newAllFormsPages, 'name');
            const formTypes = uniqueForms?.map((ft) => {
                const type = Object.values(FormTypes).find((f) =>
                    ft?.route?.toLocaleLowerCase().includes(f?.split('-')[0])
                );
                return { type: type };
            });
            // FormTypes
            return formTypes;
        }
        return [{ type: FormTypes.CONSENT_COMMUNICATION }];
    }, [allFormsPages]);

    /** Back Button Function */
    const handleBack = useCallback(() => {
        //* * All Forms Flow */
        if (isAllForms) {
            //* * Setting Previous Page */
            movePage(whichEnum.PREVIOUS, history, null, !!user);
            return false;
        }

        //* * Normal Flow */
        if (currentStepNo >= 0 && stepsEnum[getStepName(whichEnum.PREVIOUS)]) {
            history.push(`/consent-communication?step=${stepsEnum[getStepName(whichEnum.PREVIOUS)]}`);
        } else {
            // Redirect to welcome page
            history.push('/consent-communication-welcome');
        }
    }, [stepsEnum[getStepName(whichEnum.PREVIOUS)]]);

    /** Continue Button Function */
    const handleContinue = async () => {
        const isInsurancePublic = insuranceType === 'PUBLIC';
        const isSelfPayer = insuranceType === 'SELFPAYER';
        //* * API DATA BODY */
        const API_BODY = {
            instances: isAllForms ? getAllFormTypes : [{ type: FormTypes.CONSENT_COMMUNICATION }],
            ...(user && {
                user_id: user?.id,
                professional: {
                    id: '2c4f8fa7-e423-48f9-add0-c6d6dabc1475',
                    key: 'hh-herakles-therapiezentrum',
                    name: 'Herakles Therapiezentrum',
                    ...(user && { ext_id: '62d0855a166e395cf4fd81ea' }),
                },
            }),
            ...((!user || isAllFormsLastPage) && {
                personal_data: {
                    professional: {
                        id: '2c4f8fa7-e423-48f9-add0-c6d6dabc1475',
                        key: 'hh-herakles-therapiezentrum',
                        name: 'Herakles Therapiezentrum',
                        ...(user && { ext_id: '62d0855a166e395cf4fd81ea' }),
                    },
                    personal: {
                        title: formData?.title,
                        first_name: formData?.firstName.trim(),
                        middle_name: formData?.middleName?.trim(),
                        last_name: formData?.lastName.trim(),
                        date_of_birth: dayjs(formData?.dob, 'DD.MM.YYYY', true).isValid()
                            ? dayjs(formData?.dob, 'DD.MM.YYYY').format('YYYY-MM-DD')
                            : formData?.dob,
                        email: formData?.email,
                        phone: formData?.phoneNo?.number || '',
                        ...(isAllForms && isAllFormsContain(allFormsPages, ALL_FORM_BASE_URLS.COURSE) && other
                            ? { other: other }
                            : {}),
                    },
                    contact: {
                        street: formData?.street,
                        zip: formData?.zipCode,
                        city: formData?.city,
                        country: formData?.country?.name,
                    },
                    ...(isAllForms &&
                        isAllFormsContain(allFormsPages, ALL_FORM_BASE_URLS.COURSE) && {
                            health_insurance: {
                                insurance_type:
                                    insuranceType?.toUpperCase() === 'SELFPAYER'
                                        ? 'SELF_PAYER'
                                        : insuranceType?.toUpperCase(),
                                ...(!isSelfPayer && {
                                    insurance_id: insuranceId || '',
                                    insurance_company: insuranceCompany,
                                }),
                                ...(isInsurancePublic && {
                                    insurance_company_id: insuranceNumber,
                                    insurance_valid_until: getISODate(
                                        validUntil
                                            .replaceAll(lan === 'en' && '.', '/')
                                            .replaceAll(lan === 'de' && '/', '.'),
                                        lan
                                    ),
                                    insurance_status: insuranceStatus.toUpperCase(),
                                }),
                            },
                        }),
                },
            }),
            misc_data: {
                communication_preference: {
                    email: !!email,
                    sms: !!sms,
                    other: !!other,
                    ...(!!other && { other_type: other }),
                },
                ...(isAllForms &&
                    isAllFormsContain(allFormsPages, ALL_FORM_BASE_URLS.COURSE) && {
                        additional_info: formData?.comment || '',
                        course: [
                            {
                                type: 'Rückenkurs',
                                start_date: courseStartDate || '',
                            },
                        ],
                    }),
            },
            consent: true,
        };

        const COMMUNICATION_BODY = {
            user_id: user?.id,
            category: FormTypes.CONSENT_COMMUNICATION,
            misc_data: {
                communication_preference: {
                    email: !!email,
                    sms: !!sms,
                    other: !!other,
                    ...(!!other && { other_type: other }),
                },
            },
            professional: {
                id: '2c4f8fa7-e423-48f9-add0-c6d6dabc1475',
                key: 'hh-herakles-therapiezentrum',
                name: 'Herakles Therapiezentrum',
                ...(user && { ext_id: '62d0855a166e395cf4fd81ea' }),
            },
            consent: true,
        };

        //* * Password Page */
        const body = {
            title: formData?.title || '-',
            firstName: formData?.firstName || '',
            lastName: formData?.lastName || '',
            dob: formData?.dob || '',
            email: formData?.email || '',
            street: formData?.street || '',
            zipCode: formData?.zipCode || '',
            city: formData?.city || '',
            country: formData?.country || '',
        };

        if (!user && isAllForms) {
            if (
                createAccount === toggleValues.YES &&
                !verificationCode &&
                currentStepName === stepsEnum['create-account']
            ) {
                if (!password || !confirmPassword || passwordError || confirmPasswordError) {
                    validatePassword(t);
                } else {
                    //* * Signing up new user */
                    await signUp(formData?.email, confirmPassword, body);

                    //* * All Forms Flow */
                    if (isAllForms) {
                        const allForms = clone(allFormsPages);
                        const lastItem = allForms[allForms.length - 2];
                        const accountPage = {
                            name: lastItem.name,
                            route: `${lastItem.route.split('?')[0]}?step=verification`,
                            active: lastItem.active,
                            welcome: lastItem.welcome,
                        };
                        allForms.splice(allForms.length - 2, 0, accountPage); // insert at second last index
                        setAllFormsPages(allForms); // update allForms with verification page
                        //* * Setting Next Page */
                        movePage(whichEnum.NEXT, history, allForms, !!user);
                        return false;
                    }
                    //* * Redirecting to next page */
                    history.push(`/consent-communication?step=${stepsEnum[getStepName(whichEnum.NEXT)]}`);
                }

                return;
            }
            if (
                createAccount === toggleValues.NO &&
                !verificationCode &&
                currentStepName === stepsEnum['create-account']
            ) {
                //* * All Forms Flow */
                const allForms = clone(allFormsPages);
                const withoutVerification = allForms?.filter((f) => !f.route.includes('verification'));
                const filteredAllForms = withoutVerification.map((p) => {
                    p.active = false;
                    return p;
                });
                const index = withoutVerification.findIndex((f) => f.route.includes('create-account'));
                filteredAllForms[index].active = true;
                setAllFormsPages(filteredAllForms);
                //* * Setting Next Page */
                movePage(whichEnum.NEXT, history, filteredAllForms, !!user);
                return false;
            }
            if (verificationCode && currentStepName === stepsEnum.verification) {
                //* * Verification Page */
                await verifyCode(signUpUser || signUpUserContext, verificationCode, body, confirmPassword);
                if (!isVerifying && !verificationError && verificationResponse) {
                    if (isAllForms) {
                        //* * Setting Next Page */
                        movePage(whichEnum.NEXT, history, null, !!user);
                        return false;
                    }
                    history.push(`/consent-communication?step=${stepsEnum[getStepName(whichEnum.NEXT)]}`);
                }
                return;
            }
        }

        //* * Normal Flow | Summary Page */
        if (isLastPage) {
            setSummaryEdit(false); // resetting edit state
            // Summary Page
            if (isAllFormsLastPage) {
                await sendConsentForm({
                    body: API_BODY,
                    isLastPage: isLastPage,
                    isAllFormsLastPage: false,
                    isCommunication: false, // false: to prevent two api calls on summary page
                    isHerakles: true,
                });

                if (!apiLoading && isAllForms) {
                    //* * Setting Next Page */
                    movePage(whichEnum.NEXT, history, null, !!user);
                    return false;
                }
            } else {
                if (isAllForms) {
                    //* * Setting Next Page */
                    movePage(whichEnum.NEXT, history, null, !!user);
                    return false;
                }
                if (user) {
                    await sendConsentForm({ body: API_BODY, isLastPage: isLastPage, isHerakles: true });
                    if (!apiLoading) {
                        //* * All Forms Flow */
                        history.replace(`/consent-communication-thankyou`);
                    }
                } else {
                    await sendConsentForm({
                        body: API_BODY,
                        isLastPage: false,
                        isCommunication: true,
                        isHerakles: true,
                    });
                    if (!apiLoading) {
                        //* * All Forms Flow */
                        history.replace(`/consent-communication-thankyou`);
                    }
                }
            }
        }

        /** If confirmed */
        if (currentStepNo >= 0 && stepsEnum[getStepName(whichEnum.NEXT)]) {
            //* * PERSONAL DETAILS PAGE */
            if (currentStepName === getKeyByValue(pageTitles, pageTitles['personal-information'])) {
                // Required fields
                const localFormData = {
                    title: formData?.title || '-',
                    firstName: formData?.firstName || '',
                    lastName: formData?.lastName || '',
                    dob: formData?.dob || '',
                    email: formData?.email || '',
                    street: formData?.street || '',
                    phoneNo: formData?.phoneNo?.number || '',
                    zipCode: formData?.zipCode || '',
                    city: formData?.city || '',
                    country: formData?.country || '',
                };

                const { email } = formData;

                // Copy Of Errors State
                const copyErrors = { ...errors };

                // Set Email Error Message
                const setEmailError = (err) => {
                    copyErrors.emailError = err;
                    setErrors(copyErrors);
                };

                Object.keys(localFormData).forEach((form) => {
                    if (form === 'email') {
                        setErrors((prev) => ({ ...prev, emailError: '' }));

                        if (localFormData.email === '') {
                            copyErrors.emailError = 'requiredError';
                            setErrors(copyErrors);
                        } else if (!isEmail(email)) {
                            copyErrors.emailError = 'invalidEmailError';
                            setErrors(copyErrors);
                        } else if (user) {
                            copyErrors.emailError = '';
                            setErrors(copyErrors);
                        } else {
                            if (isFetching) return;
                            // Wait for API request to complete
                            setIsFetching(true);

                            emailExists(localFormData.email, setEmailError, 'emailAlreadyExistError')
                                .then(() => {
                                    setIsFetching(false);
                                })
                                .catch((err) => {
                                    console.debug('Error', err);
                                    setIsFetching(false);
                                });
                        }
                    } else if (form === 'phoneNo') {
                        copyErrors[`${form}Error`] = '';
                        setErrors(copyErrors);

                        // Checking Null Error Message
                        if (localFormData.phoneNo === '' || localFormData.phoneNo?.length <= 5) {
                            copyErrors[`${form}Error`] = 'requiredError';
                            setErrors(copyErrors);
                        }
                    } else if (form === 'dob') {
                        setErrors((prev) => ({ ...prev, dobError: '' }));

                        if (localFormData.dob === '') {
                            copyErrors.dobError = 'requiredError';
                            setErrors(copyErrors);
                        } else if (!validateDOB(localFormData.dob, lan)) {
                            copyErrors.dobError = 'dobError';
                            setErrors(copyErrors);
                        } else {
                            copyErrors.dobError = '';
                            setErrors(copyErrors);
                        }
                    } else {
                        // Empty Error Message
                        copyErrors[`${form}Error`] = '';
                        setErrors(copyErrors);

                        // Checking Null Error Message
                        if (localFormData[form] === '') {
                            copyErrors[`${form}Error`] = 'requiredError';
                            setErrors(copyErrors);
                        }
                    }
                });

                const oMap = (obj) => Object.values(obj);
                const isValidate = oMap(localFormData)?.every((d) => d !== '') && oMap(errors).every((d) => d === '');
                if (isValidate) {
                    //* * All Forms Flow */
                    if (isAllForms) {
                        //* * Setting Next Page */
                        movePage(whichEnum.NEXT, history, null, !!user);
                        return false;
                    }
                    history.push(`/consent-communication?step=${stepsEnum[getStepName(whichEnum.NEXT)]}`);
                }
            } else if (currentStepName === getKeyByValue(pageTitles, pageTitles['communication-and-contract'])) {
                // Registered Flow
                if (userType === UserType.REGISTERED) {
                    //* * All Forms Flow */
                    if (isAllForms) {
                        //* * Setting Next Page */
                        movePage(whichEnum.NEXT, history, null, !!user);
                        return false;
                    }
                    await sendConsentForm({
                        body: COMMUNICATION_BODY,
                        isLastPage: false,
                        isCommunication: true,
                        isHerakles: true,
                    });
                    history.push(`/consent-communication?step=${stepsEnum[getStepName(whichEnum.NEXT)]}`);
                } else {
                    //* * All Forms Flow */
                    if (isAllForms) {
                        //* * Setting Next Page */
                        movePage(whichEnum.NEXT, history, null, !!user);
                        return false;
                    }
                    history.push(`/consent-communication?step=${stepsEnum[getStepName(whichEnum.NEXT)]}`);
                }
            } else if (!isLastPage) {
                //* * All Forms Flow */
                if (isAllForms) {
                    //* * Setting Next Page */
                    movePage(whichEnum.NEXT, history, null, !!user);
                    return false;
                }
                history.push(`/consent-communication?step=${stepsEnum[getStepName(whichEnum.NEXT)]}`);
            }
        }
    };

    return (
        <Grid container className={classes.ContainerBody}>
            {!matchesSm && (
                <Typography className={clsx(classes.mainHeading, classes.fontXl, classes.mb15)}>
                    {formCount}{' '}
                    {consentFormsContent
                        ? consentFormsContent?.heraklesContent?.title
                        : 'Data protection patient information'}
                </Typography>
            )}
            <Grid container>
                <Grid item md={4} sm={10} xs={10} className={classes.sidebarMenu}>
                    <StepMenu
                        title={`${formCount} ${
                            consentFormsContent
                                ? consentFormsContent?.heraklesContent?.menuTitle
                                : 'Data protection patient information'
                        }`}
                        data={consentFormsContent?.communicationNavSteps}
                        currentStepNo={currentStepNo}
                    />
                </Grid>
                <Grid item md={8} sm={12} xs={12}>
                    <CardContainer classNames={matchesSm || matchesXs ? [classes.px20] : []}>
                        <div className={classes.progressContainer}>
                            <Typography className={clsx(classes.progressText, classes.mb5)}>
                                {`${pagesStepsNo({
                                    currentStepNo: currentStepNo,
                                    pages: pages,
                                    user: user,
                                    isNotLastForm: isNotLastForm,
                                })} ${pageTitles[step]}`}
                            </Typography>
                            <BorderLinearProgress
                                variant="determinate"
                                value={((currentStepNo + 1) * 100) / Object.keys(pages).length}
                            />
                        </div>
                        <div>{renderPage()}</div>
                        {/* Footer Buttons */}
                        <div className={clsx(classes.footerButtons, classes.mt30)}>
                            <OutlinedButton
                                {...((isLastPage && isVerifying) || apiLoading ? { disabled: true } : {})}
                                text={consentFormsContent?.buttons.back || 'Back'}
                                onPress={handleBack}
                                fullWidth={matchesXs}
                            />
                            <FilledButton
                                //* * Toggle Enable/Disable Button */
                                {...(isLastPage ? { disabled: !confirmSummary } : {})}
                                {...(step === stepsEnum['communication-and-contract']
                                    ? {
                                          disabled:
                                              !isCommunicationEnabled ||
                                              isCommunicationCheck ||
                                              isCheckDisabled ||
                                              apiLoading ||
                                              isAccountDisabled ||
                                              isLoading ||
                                              isVerifying,
                                      }
                                    : {})}
                                {...(currentStepName === stepsEnum['create-account']
                                    ? { disabled: isAccountDisabled || isLoading || isVerifying || apiLoading }
                                    : {})}
                                {...(step === 'verification' ? { disabled: !verificationCode } : {})}
                                loading={isLoading || isVerifying || apiLoading}
                                text={getNextButtonText()}
                                onPress={handleContinue}
                                fullWidth={matchesXs}
                                marginBottom={matchesXs && 20}
                            />
                        </div>
                    </CardContainer>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HeraklesCommunicationForms;
