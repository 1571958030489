import { Slider, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { debounce } from 'lodash';
import { memo, useContext, useState } from 'react';

import { ContextProviderTwo } from '@/context/DoctorsContextTwo';

import { useStyles } from './LifeStyleStyles';

const useStyless = makeStyles((theme) => ({
    topHeading: {
        fontFamily: 'MessinaSans-Regular',
        fontWeight: 600,
        fontSize: '21px',
        marginBottom: 20,
    },
    paddingLabel: {
        paddingRight: 5,
        paddingLeft: 5,
        // background: 'green',
    },
    valueLabel: {
        left: 0,
        color: 'transparent',
        backgroundColor: 'white',
        width: 'auto',
        paddingRight: 3,
        paddingLeft: 3,
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        '& > span': {
            width: '8em',
            textAlign: 'center',
            color: 'transparent',
        },
        '& > span > span': {
            ...theme.typography.subheading,
        },
    },
    valueLabelTwo: {
        left: 'auto',
        color: 'transparent',
        backgroundColor: 'white',
        width: 'auto',
        paddingRight: 3,
        paddingLeft: 3,
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        '& > span': {
            width: '8em',
            textAlign: 'center',
            color: 'transparent',
        },
        '& > span > span': {
            ...theme.typography.subheading,
        },
    },
    valueLabelThree: {
        left: '-7em',
        color: 'transparent',
        backgroundColor: 'white',
        width: 'auto',
        paddingRight: 3,
        paddingLeft: 3,
        boxShadow: `0 0 0 2px ${theme.palette.common.yellow}`,
        borderRadius: 8,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        '& > span': {
            width: '8em',
            textAlign: 'center',
            color: 'transparent',
        },
        '& > span > span': {
            ...theme.typography.subheading,
        },
    },
}));

const PrettoSlider = withStyles((theme) => ({
    root: {
        color: '#ABABAB',
        height: 2,
    },
    track: {
        height: 8,
        borderRadius: 4,
        color: theme.palette.common.yellow,
    },
    rail: {
        height: 8,
        borderRadius: 4,
        opacity: 1,
    },
}))(Slider);

const LifeStyleYourSleep = ({ mainData }) => {
    const classess = useStyless();
    const classes = useStyles();
    const { sleepHours, setSleepHours } = useContext(ContextProviderTwo);
    const [localSleepHours, setLocalSleepHours] = useState('');

    const handleChange = (e, newValue) => {
        setLocalSleepHours(newValue);
    };

    const handleDragEnd = () => {
        const db = debounce(() => {
            setSleepHours(localSleepHours);
        }, 500);
        db();
    };

    const valueLabelFormat = (sleepHours) => {
        return (
            <div className={classess.paddingLabel}>{`${sleepHours || 5} ${mainData.sleep_measure_label[0].text}`}</div>
        );
    };
    return (
        <div style={{ marginTop: 30 }}>
            <Typography className={classes.mainHeading} style={{ paddingBottom: 20 }}>
                {mainData.sleep_question_1_how_many_hrs[0].text}
            </Typography>
            <div className={classes.questionContainer}>
                <div className={classes.answersContainer} style={{ marginTop: 30 }}>
                    <PrettoSlider
                        valueLabelDisplay="on"
                        value={localSleepHours || sleepHours}
                        onChange={handleChange}
                        onChangeCommitted={handleDragEnd}
                        min={mainData?.sleep_answer_min}
                        step={mainData?.sleep_answer_step}
                        max={mainData?.sleep_answer_max}
                        getAriaValueText={valueLabelFormat}
                        valueLabelFormat={valueLabelFormat}
                        classes={{
                            valueLabel:
                                sleepHours < 5
                                    ? classess.valueLabel
                                    : sleepHours > 5 && sleepHours <= 9
                                      ? classess.valueLabelTwo
                                      : sleepHours > 9
                                        ? classess.valueLabelThree
                                        : classess.valueLabel,
                            thumb: classes.thumb,
                        }}
                    />
                    <div className={classes.rangeLabelContainer}>
                        <Typography className={classes.rangeLabelText}>{`<${mainData?.sleep_answer_min}`}</Typography>
                        <Typography className={classes.rangeLabelText}>{`${mainData?.sleep_answer_max}+`}</Typography>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(LifeStyleYourSleep);
