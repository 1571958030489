import { Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
// custom hooks
import { orderBy } from 'lodash';
import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DownArrowIcon from '@/assets/Icons/DownArrowIcon.svg';
import SortDownInactive from '@/assets/Icons/SortDown_inactive.svg';
// icons
import SortUpActive from '@/assets/Icons/SortUp_active.svg';
import SortUpInactive from '@/assets/Icons/SortUp_inactive.svg';

// contexts
import { Context as ConsentManagerContext, showBtnStatus } from '@/context/ConsentManagerContext';

import { dayjs } from '@/utils/dayjsSetup.js';
import { formatDateForTable } from '@/utils/helpers.js';

import { usePersistedPrismicStore } from '@/store.js';

// table row
import TableRow from './TableRow';
// custom styles
import tableStyles from './tableStyles';

const columnName = {
    TYPE: 'type',
    SHARED: 'shared',
    DATE: 'date',
};

const orderByEnum = {
    ASC: 'asc',
    DESC: 'desc',
};

const sortOrderEnum = {
    ASC: 'asc',
    DESC: 'desc',
    NONE: '',
};

const Table = ({ doctorDocuments }) => {
    const classes = tableStyles();
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('xs'));
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const { i18n } = useTranslation();

    const {
        state: { tableType, mainDocuments, showBtn, sortType, sortDoctor, sortDate, searchStarted, allDocuments },
        setMainDocuments,
        setShowBtn,
        setAllDocuments,
        setSortType,
        setSortDoctor,
        setSortDate,
    } = useContext(ConsentManagerContext);

    const { [i18n.language]: consentPrismicData } = usePersistedPrismicStore((state) => state.consentData);
    const { loading: contentLoading, content: consentData } = consentPrismicData;

    const [DocTypes, setDocTypes] = useState(null);

    useEffect(() => {
        if (!contentLoading) {
            setDocTypes(consentData.data_operations_table_document_types);
        }
    }, [contentLoading]);

    // setting type to prismic type text
    const setDocumentTypeToPrismic = useCallback(
        (type) => {
            if (!DocTypes) return;
            const filteredType = DocTypes.filter((prismicType) => {
                return prismicType.data_operations_table_document_types_backend === type;
            });
            return filteredType[0];
        },
        [DocTypes]
    );

    const colName = tableType
        ? consentData.data_operations_table_document_column[0].text
        : consentData.data_operations_table_shared_with_column[0].text;

    const colNameType = tableType ? columnName.TYPE : columnName.SHARED;

    const sortNameType = tableType ? sortType : sortDoctor;
    const sortColType = matchesXs ? sortDate : sortNameType;

    const sortColTypeFirst = tableType ? sortDoctor : sortType;
    const sortColTypeLastCol = matchesXs ? sortNameType : sortDate;

    useEffect(() => {
        if (doctorDocuments) {
            let tempArr = [];
            doctorDocuments.forEach((doc) => {
                tempArr.push({
                    id: doc.id,
                    name: doc.name,
                    doctor_name: doc.doctor_name,
                    type: doc.doc_type,
                    created_date: doc.created_date,
                });
            });
            /**
             * The rows are sorted by date in a descending order
             */
            tempArr = orderBy(tempArr, [(o) => dayjs(o.created_date)], ['desc']);
            setMainDocuments(tempArr);
            setAllDocuments(doctorDocuments);
        }
    }, [doctorDocuments]);

    useEffect(() => {
        if (mainDocuments)
            if (mainDocuments.length > 5 && !searchStarted && showBtn !== showBtnStatus.SHOWED)
                setShowBtn(showBtnStatus.SHOW);
    }, [mainDocuments]);

    const handleShowBtn = useCallback(() => {
        setShowBtn(showBtnStatus.SHOWED);
    }, [showBtn]);

    const handleSort = (col, order) => {
        const columnMap = {
            type: {
                setter: (type) => setSortType(type),
                type: sortType,
            },
            shared: {
                setter: (type) => setSortDoctor(type),
                type: sortDoctor,
            },
            date: {
                setter: (type) => setSortDate(type),
                type: sortDate,
            },
        };
        if (columnMap[col].type === order) {
            columnMap[col].setter(null);
            return;
        }
        columnMap[col].setter(order);
    };

    useEffect(() => {
        const tempColOrders = [sortType, sortDoctor, sortDate];
        const tempColNames = ['type', 'doctor_name', 'created_date'];
        const colOrders = [];
        const colNames = [];
        const tempArr = mainDocuments ? [...mainDocuments] : [];
        const arrayWithoutDrPrefix = tempArr.length
            ? tempArr.map((d) => ({
                  ...d,
                  doctor_name: d.doctor_name?.split('.')[d.doctor_name?.split('.').length - 1].trim(),
              }))
            : tempArr;

        tempColOrders.forEach((colName, index) => {
            if (colName) {
                /** Column Orders */
                colOrders.push(colName);
                /** Column Names */
                colNames.push(
                    tempColNames[index] === 'created_date' ? (o) => dayjs(o.created_date) : tempColNames[index]
                );
            }
        });
        if (sortType !== null || sortDoctor !== null || allDocuments) {
            const orderedData = orderBy(arrayWithoutDrPrefix, colNames, colOrders);
            const newOrderedData = orderedData.map((data) => {
                const doctor = tempArr.find((d) => d.doctor_name.includes(data.doctor_name));
                return {
                    ...data,
                    doctor_name: doctor.doctor_name,
                };
            });

            setMainDocuments(newOrderedData);
        }
    }, [sortType, sortDoctor, sortDate]);

    const [sort, setSort] = useState(sortOrderEnum.NONE);
    const handleColName = useCallback(
        (col) => {
            handleSort(
                col,
                sort === sortOrderEnum.ASC
                    ? sortOrderEnum.ASC
                    : sort === sortOrderEnum.DESC
                      ? sortOrderEnum.DESC
                      : sortOrderEnum.NONE
            );
            setSort(
                sort === sortOrderEnum.NONE
                    ? sortOrderEnum.ASC
                    : sort === sortOrderEnum.ASC
                      ? sortOrderEnum.DESC
                      : sortOrderEnum.NONE
            );
        },
        [sort]
    );

    return (
        <Grid container className={classes.table}>
            {/* Table Header */}
            <Grid container className={classes.tableHeader}>
                <Grid
                    item
                    sm={4}
                    xs={6}
                    className={classes.colHeading}
                    onClick={() => handleColName(tableType ? columnName.SHARED : columnName.TYPE)}
                >
                    <Typography className={classes.colHeadingText}>
                        {tableType
                            ? consentData.data_operations_table_shared_with_column[0].text
                            : consentData.data_operations_table_document_column[0].text}
                    </Typography>
                    <div className={classes.sortIcons}>
                        <img
                            alt=""
                            onClick={(e) => {
                                e?.stopPropagation();
                                handleSort(tableType ? columnName.SHARED : columnName.TYPE, 'asc');
                            }}
                            src={sortColTypeFirst === orderByEnum.ASC ? SortUpActive : SortUpInactive}
                            className={classes.sortIcon}
                        />
                        <img
                            alt=""
                            onClick={(e) => {
                                e?.stopPropagation();
                                handleSort(tableType ? columnName.SHARED : columnName.TYPE, 'desc');
                            }}
                            src={sortColTypeFirst === orderByEnum.DESC ? SortUpActive : SortDownInactive}
                            className={classes.sortIcon}
                            style={{
                                transform: sortColTypeFirst === orderByEnum.DESC ? 'scaleY(-1)' : null,
                                filter: sortColTypeFirst === orderByEnum.DESC ? 'FlipV' : null,
                            }}
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    sm={5}
                    xs={6}
                    className={clsx(classes.colHeading, matchesXs ? classes.jcEnd : null)}
                    onClick={() => handleColName(matchesXs ? columnName.DATE : colNameType)}
                >
                    <Typography
                        className={clsx(classes.colHeadingText, matchesXs ? classes.textCenter : null)}
                        style={{ paddingLeft: matches ? '1rem' : null }}
                    >
                        {matchesXs ? consentData.data_operations_table_date_column[0].text : colName}
                    </Typography>
                    <div className={classes.sortIcons}>
                        <img
                            alt=""
                            onClick={(e) => {
                                e?.stopPropagation();
                                handleSort(matchesXs ? columnName.DATE : colNameType, 'asc');
                            }}
                            src={sortColType === orderByEnum.ASC ? SortUpActive : SortUpInactive}
                            className={classes.sortIcon}
                        />
                        <img
                            alt=""
                            onClick={(e) => {
                                e?.stopPropagation();
                                handleSort(matchesXs ? columnName.DATE : colNameType, 'desc');
                            }}
                            src={sortColType === orderByEnum.DESC ? SortUpActive : SortDownInactive}
                            className={classes.sortIcon}
                            style={{
                                transform: sortColType === orderByEnum.DESC ? 'scaleY(-1)' : null,
                                filter: sortColType === orderByEnum.DESC ? 'FlipV' : null,
                            }}
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    sm={3}
                    xs={6}
                    className={classes.colHeading}
                    onClick={() => handleColName(matchesXs ? colNameType : columnName.DATE)}
                >
                    <Typography className={clsx(classes.colHeadingText, matchesXs ? null : classes.textCenter)}>
                        {matchesXs ? colName : consentData.data_operations_table_date_column[0].text}
                    </Typography>
                    <div className={classes.sortIcons}>
                        <img
                            alt=""
                            onClick={(e) => {
                                e?.stopPropagation();
                                handleSort(matchesXs ? colNameType : columnName.DATE, 'asc');
                            }}
                            src={sortColTypeLastCol === orderByEnum.ASC ? SortUpActive : SortUpInactive}
                            className={classes.sortIcon}
                        />
                        <img
                            alt=""
                            onClick={(e) => {
                                e?.stopPropagation();
                                handleSort(matchesXs ? colNameType : columnName.DATE, 'desc');
                            }}
                            src={sortColTypeLastCol === orderByEnum.DESC ? SortUpActive : SortDownInactive}
                            className={classes.sortIcon}
                            style={{
                                transform: sortColTypeLastCol === orderByEnum.DESC ? 'scaleY(-1)' : null,
                                filter: sortColTypeLastCol === orderByEnum.DESC ? 'FlipV' : null,
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
            {/* Table Rows */}
            <Grid container className={classes.rowsContainer}>
                {mainDocuments &&
                    mainDocuments.map((document, index) => {
                        const { id, name, doctor_name, type, created_date } = document;
                        if (showBtn === showBtnStatus.SHOW && index >= 5 && !searchStarted) return null;
                        return (
                            <TableRow
                                key={index}
                                id={id}
                                fileName={name}
                                doctorName={doctor_name}
                                type={
                                    setDocumentTypeToPrismic(type)?.data_operations_table_document_types_frontend[0]
                                        .text
                                }
                                date={formatDateForTable(created_date)}
                            />
                        );
                    })}
            </Grid>
            {showBtn === showBtnStatus.SHOW && !searchStarted && (
                <Grid container className={classes.btnContainer}>
                    <Button
                        onClick={handleShowBtn}
                        className={classes.showMoreButton}
                        endIcon={<img alt="" src={DownArrowIcon} />}
                        disableRipple
                    >
                        {consentData ? consentData.history_show_more_button[0].text : 'Show More'}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

export default memo(Table);
